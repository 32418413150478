<template>
	<div>
		<el-form
			ref="queryForm"
			class="query-box"
			v-model="queryParams"
			:inline="true"
		>
			<el-form-item label="提现时间" prop="name" label-width="120px">
				<el-date-picker
					size="small"
					v-model="createDateRange"
					type="daterange"
					@change="dateChange"
					range-separator="-"
					value-format="yyyy-MM-dd"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="提现订单" prop="orderNo" label-width="120px">
				<el-input
					v-model="queryParams.orderNo"
					clearable
					placeholder="请输入提现订单"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item
				label="代理商/机构编号"
				prop="name"
				label-width="120px"
			>
				<el-input
					v-model="queryParams.userNo"
					clearable
					placeholder="请输入代理商/机构编号"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item label="账户类型" prop="name" label-width="120px">
				<el-select
					v-model="queryParams.balanceType"
					placeholder="请选择账户类型"
					size="small"
					style="width: 240px"
				>
					<el-option label="全部" value=""></el-option>
					<el-option label="交易分润" value="POS_PROFIT"></el-option>
					<el-option
						label="活动分润"
						value="ACTIVE_PROFIT"
					></el-option>
				</el-select>
			</el-form-item>
			<!-- 上游通道 -->
			<el-form-item label="上游通道" prop="channelNo" label-width="120px">
				<el-select
					v-model="queryParams.channelNo"
					placeholder="请选择上游通道"
					style="width: 240px"
					clearable
				>
					<el-option
						v-for="item in acqChannelList"
						:label="item.name"
						:value="item.channelNo"
						:key="item.channelNo"
					>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="提现状态" prop="name" label-width="120px">
				<el-select
					v-model="queryParams.extractionStatus"
					placeholder="请选择提现状态"
					size="small"
					style="width: 240px"
				>
					<el-option label="全部" value=""></el-option>
					<el-option label="初始化" value="INIT"></el-option>
					<el-option label="提现成功" value="SUCCESS"></el-option>
					<el-option label="提现失败" value="FAILED"></el-option>
					<el-option label="提现中" value="WAITING"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="打款状态" prop="name" label-width="120px">
				<el-select
					v-model="queryParams.transferStatus"
					placeholder="请选择打款状态"
					size="small"
					style="width: 240px"
				>
					<el-option label="全部" value=""></el-option>
					<el-option label="初始化未出款" value="INIT"></el-option>
					<el-option label="成功" value="SUCCESS"></el-option>
					<el-option label="失败" value="FAILED"></el-option>
					<el-option label="已冲正" value="REVERSED"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item
				label="审核状态"
				prop="auditStatus"
				label-width="120px"
			>
				<el-select
					v-model="queryParams.auditStatus"
					placeholder="请选择审核状态"
					size="small"
					style="width: 240px"
				>
					<el-option label="全部" value=""></el-option>
					<el-option label="审核中" value="WAITING"></el-option>
					<el-option label="审核成功" value="SUCCESS"></el-option>
					<el-option label="审核失败" value="FAILED"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="打款流水" prop="name" label-width="120px">
				<el-input
					v-model="queryParams.lsApplyId"
					clearable
					placeholder="请输入打款流水"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item label="打款时间" prop="name" label-width="120px">
				<el-date-picker
					size="small"
					v-model="updateDateRange"
					type="daterange"
					@change="dateChange2"
					range-separator="-"
					value-format="yyyy-MM-dd"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				>
				</el-date-picker>
			</el-form-item>
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleQuery"
					v-permission="'EXTRACTION_ORDER_QUERY'"
				>
					查询
				</el-button>
				<el-button
					icon="el-icon-refresh"
					size="mini"
					@click="handleResetQuery"
					v-permission="'EXTRACTION_ORDER_QUERY'"
				>
					重置
				</el-button>
				<el-button
					icon="el-icon-download"
					size="mini"
					@click="handleExport"
					v-permission="'EXTRACTION_ORDER_EXPORT'"
				>
					导出
				</el-button>
				<el-button
					size="mini"
					@click="handleAudit"
					v-permission="'EXTRACTION_ORDER_AUDIT'"
				>
					批量审核
				</el-button>
			</el-col>
		</el-row>
		<CustomForm
			:dicts="dicts"
			:on-fresh="showCustomForm"
			@on-close="handlerCustomFormOnclose"
			isBatch
		></CustomForm>
	</div>
</template>

<script>
import CustomForm from "./CustomForm";
import { TerminalApi, WithdrawApi } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: {}
		}
	},
	components: { CustomForm },
	data() {
		return {
			queryParams: {},
			createDateRange: [],
			updateDateRange: [], //完成时间
			acqChannelList: [], //上游通道
			showCustomForm: false,
			dateVal: "",
			dateVal2: ""
		};
	},
	async mounted() {
		//上游通道列表
		const result = await TerminalApi.acqChannel.listAllChannel();
		this.acqChannelList = (result && result.data) || [];
	},
	methods: {
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.commitChange();
		},
		handleQuery() {
			this.commitChange();
		},
		handleResetQuery() {
			this.queryParams = {};
			this.createDateRange = [];
			this.updateDateRange = [];
			this.commitChange();
		},
		handleAudit() {
			this.showCustomForm = true;
		},
		handleExport() {
			this.$confirm("确定导出提现订单", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(async () => {
				this.dealWithQueryParams();
				let result = await WithdrawApi.extractionOrder.export(
					this.queryParams
				);
				if (result.success) {
					this.downloadFile(result.data);
				}
			});
		},
		dealWithQueryParams() {
			console.log("dealWithQueryParams----->", this.createDateRange);
			// 判断如果有时间则重置为空数组
			if (!this.createDateRange) {
				this.createDateRange = [];
			}
			if (!this.updateDateRange) {
				this.updateDateRange = [];
			}
			let [beginExtractionTime, endExtractionTime] = this.createDateRange;
			this.queryParams.beginExtractionTime =
				beginExtractionTime && beginExtractionTime + " 00:00:00";
			this.queryParams.endExtractionTime =
				endExtractionTime && endExtractionTime + " 23:59:59";

			let [beginTransferTime, endTransferTime] = this.updateDateRange;
			this.queryParams.beginTransferTime =
				beginTransferTime && beginTransferTime + " 00:00:00";
			this.queryParams.endTransferTime =
				endTransferTime && endTransferTime + " 23:59:59";
		},
		commitChange() {
			this.dealWithQueryParams();
			let params = { ...this.queryParams };
			this.$emit("on-change", params);
		},
		dateChange(val) {
			if (!val) {
				this.queryParams.beginExtractionTime = "";
				this.queryParams.endExtractionTime = "";
			} else {
				this.queryParams.beginExtractionTime = val[0];
				this.queryParams.endExtractionTime = val[1];
			}
		},
		dateChange2(val) {
			if (!val) {
				this.queryParams.beginTransferTime = "";
				this.queryParams.endTransferTime = "";
			} else {
				this.queryParams.beginTransferTime = val[0];
				this.queryParams.endTransferTime = val[1];
			}
		}
	}
};
</script>

<style scoped></style>
